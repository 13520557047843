import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { AutoDisplayLightboxSymbol, name } from './symbols'
import { AutoDisplayLightbox } from './autoDisplayLightbox'
import { LifeCycle } from '@wix/thunderbolt-symbols'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageDidMountHandler).to(AutoDisplayLightbox)
}

export * from './types'
export { name, AutoDisplayLightboxSymbol }
