import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { FeatureStateSymbol, IPageDidMountHandler, PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { AutoDisplayLightboxPageConfig, AutoDisplayLightboxFeatureState } from './types'
import { name } from './symbols'
import { IPopups, PopupsSymbol } from 'feature-popups'
import { IFeatureState } from 'thunderbolt-feature-state'

const autoDisplayLightboxFactory = (
	{ popupId, delay }: AutoDisplayLightboxPageConfig,
	featureState: IFeatureState<AutoDisplayLightboxFeatureState>,
	popupsFeature: IPopups
): IPageDidMountHandler => {
	return {
		pageDidMount() {
			const popupsOpened = featureState.get()
			if (!popupsOpened || !popupsOpened[popupId]) {
				setTimeout(() => {
					popupsFeature.openPopupPage(popupId)
					featureState.update((state) => ({ ...state, [popupId]: true }))
				}, delay * 1000)
			}
		},
	}
}

export const AutoDisplayLightbox = withDependencies(
	[named(PageFeatureConfigSymbol, name), named(FeatureStateSymbol, name), PopupsSymbol],
	autoDisplayLightboxFactory
)
